export const fullUrl = (
  slug?: string,
  returnUndefined = false
): string | undefined => {
  if (!slug && returnUndefined) {
    return undefined;
  }
  if (!slug) {
    return process.env.BACKEND_HOST_URL;
  }
  return process.env.BACKEND_HOST_URL + slug;
};
