import styled from "styled-components";
import { sizePx } from "../../../utils/devices";
import Button from "../../Shared/Button/Button";

export const BlocksContainer = styled.div`
  background: ${({ theme }) => theme.colors.light};
  z-index: 20;
  position: relative;
`;

export const BlocksHeading = styled.h1`
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 0;
`;

export const BlocksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Block = styled.div<{ imageUrl?: string }>`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(28, 28, 28, 0.5)),
    url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 4rem;
  height: 50vh;
  flex-basis: 45%;
  margin: 1%;
  flex-grow: 1;
  color: white;
  @media (max-width: ${sizePx.tablet}) {
    flex-basis: 100%;
    margin: 0 5% 5%;
    padding: 3rem;
    text-align: center;
  }
`;

export const BlockTitle = styled.h2`
  margin-bottom: 10px;
`;

export const BlockSubTitle = styled.h5`
  margin-bottom: 20px;
`;

export const BlockButton = styled(Button)`
  align-self: flex-end;
  @media (max-width: ${sizePx.tablet}) {
    align-self: center;
  }
`;
