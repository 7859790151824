import { Page } from "../types/page";
import Slideshow from "../components/Global/Slideshow/Slideshow";
import Blocks from "../components/Global/Blocks/Blocks";
import { GetStaticProps } from "next";
import axios from "axios";
import logger from "../services/logger";
import { PropsWithChildren } from "react";
import { CategoryModel, Model } from "../types/models";

interface Props {
  data?: { categories: Model<CategoryModel>[] };
}

const Home: Page = ({ data }: PropsWithChildren<Props>) => {
  return (
    <>
      <Slideshow />
      <Blocks data={data?.categories || []} />
    </>
  );
};

export const getServerSideProps: GetStaticProps = async () => {
  try {
    const res = await axios.get(
      `${process.env.BACKEND_API_URL}/categories?populate=image`
    );

    return {
      props: { data: { categories: await res.data?.data } },
    };
  } catch (e) {
    logger.error(e);
    throw e;
  }
};
export default Home;
