import React, { PropsWithChildren } from "react";
import {
  Block,
  BlockButton,
  BlocksContainer,
  BlocksHeading,
  BlockSubTitle,
  BlocksWrapper,
  BlockTitle,
} from "./Blocks.elements";
import { CategoryModel, Model } from "../../../types/models";
import { fullUrl } from "../../../utils/urlHelper";

interface Props {
  data: Model<CategoryModel>[];
}

const Blocks = ({ data }: PropsWithChildren<Props>) => {
  return (
    <BlocksContainer>
      <BlocksHeading id="specializace">Naše specializace</BlocksHeading>
      <BlocksWrapper>
        {data.map(({ attributes: { title, subTitle, url, image } }, key) => (
          <Block key={key} imageUrl={fullUrl(image?.data?.attributes.url)}>
            <BlockTitle>{title}</BlockTitle>
            <BlockSubTitle>{subTitle}</BlockSubTitle>
            <BlockButton inverted href={url} bgColorHover="#A7A4E0">
              Více
            </BlockButton>
          </Block>
        ))}
      </BlocksWrapper>
    </BlocksContainer>
  );
};

export default Blocks;
