import styled from "styled-components";
import { device, sizePx } from "../../../utils/devices";
import { Svg } from "../../Shared/Svg/Svg";

export const SlideshowContainer = styled.div`
  position: relative;
  overflow: hidden;
`;
export const SlideImage = styled.div<{
  imageUrl: string;
}>`
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  @media (max-width: ${sizePx.tablet}) {
    height: 95vh;
    background-attachment: scroll;
  }

  &::before {
    content: " ";
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(10, 10, 10, 0.7)),
      //background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(10, 10, 10, 1)),
      url(${(props) => props.imageUrl || ""}) #122b41 no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    will-change: transform;
    z-index: -1;
  }
`;

export const SlideContent = styled.div`
  text-align: center;
  max-width: 90%;
  @media ${device.tablet} {
    max-width: 70%;
  }
  @media ${device.laptopL} {
    max-width: 50%;
  }
`;
export const SlideTitle = styled.h1<{ color: string }>`
  color: ${({ theme, color }) => (color ? color : theme.colors.accent)};
  z-index: 8;
`;
export const SlideText = styled.h3`
  color: ${({ theme }) => theme.colors.white};
  white-space: pre-line;
  margin: 2rem 0;
  line-height: 2rem;
  font-weight: 400;
`;

const SlideArrow = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
  @media (max-width: ${sizePx.tablet}) {
    display: none;
  }
`;

export const SlideArrowLeft = styled(SlideArrow)`
  background: url("/images/slideshow/arrow-left.svg") no-repeat;
  background-size: cover;
  left: 100px;
`;

export const SlideArrowRight = styled(SlideArrow)`
  background: url("/images/slideshow/arrow-right.svg") no-repeat;
  background-size: cover;
  right: 100px;
`;

export const WaveSvg = styled(Svg)`
  position: absolute;
  bottom: -1px;
  z-index: 10;
`;
