import React from "react";
import "react-slideshow-image/dist/styles.css";
import { Fade, SlideshowProps } from "react-slideshow-image";
import {
  SlideArrowLeft,
  SlideArrowRight,
  SlideContent,
  SlideImage,
  SlideshowContainer,
  SlideText,
  SlideTitle,
  WaveSvg,
} from "./Slideshow.elements";
import Button from "../../Shared/Button/Button";
import { useTheme } from "styled-components";

const slidesData = [
  {
    title: "Komplexní řešení jednoduše",
    titleColor: "#A7A4E0",
    text: "Jsme GEOSCANNING a přinášíme komplexní řešení na míru pro vaše potřeby.",
    imageUrl: "/images/slideshow/hero.jpg",
    link: "/",
  },
  // {
  //   title: "Železnice, dálnice, cesty tunely a mosty",
  //   titleColor: "#BBA600",
  //   text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.\n Integer tristique pharetra vehicula. Interdum et malesuada fames ac ante ipsum primis in faucibus Praesent sed diam congue, suscipit sapien id, suscipit dui. Maecenas nec est ac odio cursus venenatis.",
  //   imageUrl: "images/slideshow/hero1.jpg",
  //   link: "/",
  // },
  // {
  //   title: "Pasport zeleně, lesů, parků a vodo zádržné opatření, passport CO2",
  //   titleColor: "#91D6AC",
  //   text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.\n Integer tristique pharetra vehicula. Interdum et malesuada fames ac ante ipsum primis in faucibus Praesent sed diam congue, suscipit sapien id, suscipit dui. Maecenas nec est ac odio cursus venenatis.",
  //   imageUrl: "images/slideshow/hero3.jpg",
  //   link: "/",
  // },
  // {
  //   title: "Voda, Přehrady, řeky, tůně",
  //   titleColor: "#0067bb",
  //   text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.\n Integer tristique pharetra vehicula. Interdum et malesuada fames ac ante ipsum primis in faucibus Praesent sed diam congue, suscipit sapien id, suscipit dui. Maecenas nec est ac odio cursus venenatis.",
  //   imageUrl: "images/slideshow/hero4.jpg",
  //   link: "/",
  // },
  // {
  //   title: "Inženýring, potrubí, kolektory, trakční vedení",
  //   titleColor: "#ce2121",
  //   text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.\n Integer tristique pharetra vehicula. Interdum et malesuada fames ac ante ipsum primis in faucibus Praesent sed diam congue, suscipit sapien id, suscipit dui. Maecenas nec est ac odio cursus venenatis.",
  //   imageUrl: "images/slideshow/hero4.jpg",
  //   link: "/",
  // },
  // {
  //   title: "Architektura, archeologie, průzkum hornin",
  //   titleColor: "#a4611b",
  //   text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.\n Integer tristique pharetra vehicula. Interdum et malesuada fames ac ante ipsum primis in faucibus Praesent sed diam congue, suscipit sapien id, suscipit dui. Maecenas nec est ac odio cursus venenatis.",
  //   imageUrl: "images/slideshow/hero4.jpg",
  //   link: "/",
  // },
];

const options: SlideshowProps = {
  easing: "ease",
  duration: 4000,
  pauseOnHover: false,
  transitionDuration: 1250,
  canSwipe: false,
  infinite: true,
  arrows: false,
};

if (slidesData.length > 1) {
  options.arrows = true;
  options.prevArrow = <SlideArrowLeft />;
  options.nextArrow = <SlideArrowRight />;
}

const Wave = ({ color }: { color?: string }) => (
  <WaveSvg xmlns="http://www.w3.org/2000/svg" viewBox="110 120 1300 150">
    <path
      fill={color || "#fff"}
      fillOpacity="1"
      d="M0,64L48,90.7C96,117,192,171,288,192C384,213,480,203,576,202.7C672,203,768,213,864,229.3C960,245,1056,267,1152,256C1248,245,1344,203,1392,181.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
    ></path>
  </WaveSvg>
);

const Slideshow = () => {
  const theme = useTheme();

  // const handleClick = () => {
  //   const block = document
  //     ? document.getElementById("specializace")
  //     : undefined;
  //   if (block) block.scrollIntoView({ behavior: "smooth" });
  // };

  return (
    <SlideshowContainer>
      <Fade {...options}>
        {slidesData.map(({ imageUrl, link, text, title, titleColor }, key) => (
          <SlideImage key={key} imageUrl={imageUrl}>
            <SlideContent>
              <SlideTitle color={titleColor}>{title}</SlideTitle>
              <SlideText>{text}</SlideText>
              {/*{link && (*/}
              {/*  <Button*/}
              {/*    // href={link}*/}
              {/*    bgColorHover={titleColor}*/}
              {/*    onClick={handleClick}*/}
              {/*  >*/}
              {/*    Více*/}
              {/*  </Button>*/}
              {/*)}*/}
            </SlideContent>
          </SlideImage>
        ))}
      </Fade>
      <Wave color={theme.colors.light} />
    </SlideshowContainer>
  );
};

export default Slideshow;
